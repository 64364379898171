body {
  margin: 0;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.data-table th {
  background-color: #2B3035ff !important;
  color: #fff !important;
  border: solid 1px #fff !important;
}

.data-table td {
  background-color: #e0e0e0 !important;
  border: solid 1px #fff !important;
}

.exhibitorlogin {
  width: 450px;
  padding: 60px 0;
  margin: auto auto;
  text-align: center;
  box-sizing: border-box;
}

.exhibitorloginbody {
  position: relative;
  padding: 35px 30px 10px;
  margin-bottom: 1em;
  background-color: #fff;
  border: 1px solid #ddd;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.form-group .photo-error {
  color: red !important;
  font-size: 13px !important;
  margin-bottom: 0 !important;
}

.btn-block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  background-image: linear-gradient(to bottom, #1f88a4 0%, #0a7693 100%);
}

@media (max-width: 576px) {
  .exhibitorlogin {
    max-width: 300px;
    margin: 0 auto;
  }
}

.btn-color {
  background-color: #e0e0e0 !important;
  color: black !important;
  border-color: black !important;
}

.download-pics-td {
  padding-left: 50px !important;
  width: 30% !important;
  height: 23px !important;
}

.download-pics-checkbox {
  width: 20px !important;
  height: 20px !important;
}

.download-pics-form {
  max-width: 450px;
  margin: 0 auto;
}

.download-pics-form .form-group {
  display: flex;
  align-items: center;
}

.download-pics-form .form-group .form-label {
  width: 70% !important;
}

.rdw-editor-main {
  border: solid 1px #ccc;
  height: 200px;
}

.email-sms-setting {
  max-width: 80% !important;
  margin: 0 auto !important;
}

.email-sms-setting .mail-text-left {
  width: 25%;
}

img {
  max-width: 100%;
}

.navbar-dark {
  background-color: #2B3035ff !important;
}

.footer {
  /* height: 50px !important; */
  background-color: #2B3035ff !important;
  color: #fff;
  text-align: center;
  padding: 20px 0px;
  height: auto;
  width: 100%;
  bottom: 0px;
  position: fixed;
}

.nodesure::-webkit-scrollbar {
  width: 5px;
  margin-left: 3px;
}

/* Track */
.nodesure::-webkit-scrollbar-track {
  background: #a5afae;
  border-radius: 10px;
}

/* Handle */
.nodesure::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
.nodesure::-webkit-scrollbar-thumb:hover {
  background: #2e635d;
}

.nodesure {
  width: -webkit-fill-available;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  top: 35px;
}

.switch-button {
  background-color: #0a7693;
  height: 20px;
  width: 20px;
}

.modal-img {
  max-width: 100vw;
  max-height: 100vh;
}

.img-size {
  height: 40px !important;
  width: 40px !important;
  cursor: pointer !important;
}

.exhibitor-modal {
  background-color: #fff !important;
}

.css-e784if-MuiTypography-root {
  display: none;
}

.cab-service-upload {
  margin-bottom: -2rem !important;
}

.pointer-cursor {
  cursor: pointer;
}

.form-body {
  width: 80%;
  padding: 20px;
  margin: 0 auto;
  box-shadow: 0 1px 3px 0 rgb(0, 0, 0, 10%), 0 1px 2px -1px rgb(0, 0, 0, 10%);
  border: solid 1px #c8cff5;
  border-radius: 10px;
}

.form-control {
  box-shadow: none !important;
}

.form-body-section {
  padding: 30px 0px;
}

.form-submit {
  text-align: center;
  margin-top: 20px !important;
}

.form-heading {
  text-align: center;
  margin-top: 20px;
  padding-bottom: 5px;
  border-bottom: solid 1px;
  display: inline-block;
  margin-bottom: 0 !important;
}

.plain-icon:nth-child(1) {
  margin-right: 20px;
}

.content-height {
  min-height: 58.2vh;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 6px !important;
  /* overflow: hidden; */
}

.css-4jnixx-MuiStack-root {
  overflow: hidden !important;
}

.css-a643pv-MuiStack-root {
  padding-top: 0 !important;
}

/* .btn-close {
  position: absolute;
  top: 20px;
  right: 10px;
} */
td svg {
  font-size: 25px;
}

@media (max-width: 576px) {
  .form-body {
    width: 90%;
  }
}

/* .inlarge-image {
  top: -50px;
  left: -35px;
  display: block;
  z-index: 999;
  cursor: pointer;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-in-out;
} */

/*change the number below to scale to the appropriate size*/
/* .inlarge-image:hover {
  transform: scale(4); */
/* margin-top: 50px; */
/* position: absolute;
  top: 100px;
  left: 20px; */
/* } */

.image-zoom {
  transition: transform 0.2s;
}

/* .image-zoom:hover,
.image-zoom.hovered {
  transform: scale(5);
} */

.image-modal {
  height: 500px;
  width: 900px;
  position: absolute;
  top: 200px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  background-color: #fff;
  border: solid 1px #ccc;
}

.no-data-div .no-data-heading {
  text-align: center;
  padding: 80px 0px;
  margin: 0 auto;
  width: 70%;
  border: solid 1px #c8cff5;
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 rgb(0, 0, 0, 10%), 0 1px 2px -1px rgb(0, 0, 0, 10%);
  margin-top: 50px;
}

.cursorPointer {
  cursor: pointer;
}

.register-greetings span {
  display: inline-block;
  width: 100%;
  margin-bottom: 6px;
}

.register-greetings {
  background-color: rgba(255, 255, 255, 0.9) !important;

}

.register-details {
  padding: 12px 0px;
  /* border-top: solid 1px #ccc; */
  /* border-bottom: solid 1px #ccc; */
  /* margin-top: 16px; */
}

/* .regis-greetings-bg {
  background-image: url(../src/assets/image/jgc-new-logo.png);
  background-position: center;
  background-size: 300px;
  background-repeat: no-repeat;
} */






.min-vh-80 {
  min-height: 80vh;
  /* Makes sure the container takes 80% of the viewport height */
}

.pagination {
  margin-bottom: 75px;
}

@media only screen and (max-width: 1030px) {
  .form-col {
    position: inherit !important;
  }

  .img-col {
    position: unset !important;
  }

  .img-col img {
    height: 300px !important;
  }

  .logo-img {
    display: none !important;
  }
}

@media only screen and (max-width: 500px) {
  .img-col img {
    height: 180px !important;
  }
}

.nodesure-disabled {
  color: 'grey'
}



.Download-btn:hover {
  background-color: #00610f;
}


.file-input-container {
  width: 100%;
  /* Full width of the parent container */
}

.file-input-label {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: white;
  /* Background color for the file input area */
  border: 1px solid #ced4da;
  /* Bootstrap border color */
  border-radius: 4px;
  /* Rounded corners */
  position: relative;
  height: 45px;
}

.rmsc {
  font-family: "Muli-Regular" !important;

}

.rmsc .dropdown-container {
  border: none !important;
  height: 35px;
  background-color: transparent !important;
  /* Set background to transparent */
}

.rmsc .clear-selected-button {
  display: none !important;
}

.rmsc * {
  font-size: 15px;
}


.multiselect-anilesh {
  width: 100%;
  border: 1px solid #ced4da;
  /* Bootstrap border color */
  border-radius: 5px;
}

.clip-icon {
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 50%;
  font-size: 24px;
  width: 20%;
  /* Clip icon takes up 20% of the width */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-input {
  width: 80%;
  /* File input area takes up 80% of the width */
  opacity: 0;
  /* Hide the default file input */
  position: absolute;
  /* Position it over the placeholder */
  left: 20%;
  /* Start after the clip icon */
  height: 100%;
  /* Full height of the label */
}

.placeholder-text {
  /* width: 80%; */
  text-align: center;
  color: #1d3557;
  line-height: 40px;
  height: 100%;
  position: absolute;
  left: 060px;
  top: 0;
  pointer-events: none;
  font-size: 16px;
  overflow: hidden;
}


.clip-image {
  max-width: 100%;
  margin-left: 15px;
  height: auto;
  width: 25px;
  object-fit: contain;
}


.shadow {
  box-shadow: 0 .5rem 1rem #00000026 !important;
  --bs-bg-opacity: 1;
  background-color: rgba(248, 249, 250, var(--bs-bg-opacity));
  width: 100%;
  text-align: center;
  border-radius: 10px;
}

.sec-text {
  color: #000;
  font-size: 14px;
}